.spacer {
    height: 100px;
    width: 100%;
    position: relative;
    background-color: transparent; /* Ensures the background doesn't block the circles */
}

.spacer:before, .spacer:after {
    content: '';
    position: absolute;
    left: 50%;
    width: 10px; /* Diameter of the circles */
    height: 10px; /* Diameter of the circles */
    margin-left: -5px; /* Offset by half the width to center the circle */
    background-color: #000; /* Color of the circles */
    border-radius: 50%; /* Makes the divs circular */
}

.spacer:before {
    top: 0;
    transform: translateY(-50%); /* Moves the circle up by half its height to sit on the edge */
}

.spacer:after {
    bottom: 0;
    transform: translateY(50%); /* Moves the circle down by half its height to sit on the edge */
}

.spacer .line {
    position: absolute;
    left: 50%; /* Position the line in the middle; change as needed */
    top: 10px; /* Start below the top circle */
    bottom: 10px; /* End above the bottom circle */
    width: 1px; /* Thickness of the line */
    margin-left: -0.5px; /* Offset by half the width to center the line */
    background-color: #000; /* Color of the line */
}
