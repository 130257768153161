.hero {
    height: 100vh;
    width: 100vw;
}

.headline {
    display: flex;
    flex-direction: row; /* Or column, if you want vertical stacking */
    justify-content: center; /* Centers horizontally in the container */
    align-items: center; /* Centers vertically in the container */
    height: 100%; /* You might need to set a specific height if it's not filling its container */
}

.profileImage {
    display: inline-block; /* This is not necessary with flex items, you can remove it */
    width: 300px;
    height: 300px;
    border-radius: 50%;
    border: solid 1px #eeeeee;
    background-image: url("./assets/profile.png"), linear-gradient(to bottom, #ffffff 0%, #cccccc 100%);
    background-size: cover;
    background-position: center;
    margin-right: 20px;
}

.headlineText {
    align-items: center; /* Aligns the text vertically */
    justify-content: center; /* Aligns the text horizontally, if needed */
}

.headlineText h1 {
    font-size: 3em;
    font-weight: 300;
    margin: 0;
}

.headlineText h2 {
    font-size: 1em;
    font-weight: 300;
    margin: 0;
}

.socialIcons {
    margin-top: 10px;
}

.socialIcons a {
    display: inline-block;
}

.socialIcon {
    width: 25px;
    height: 25px;
    background-size: cover;
    margin-right: 5px;
}

.socialIcon.linkedin {
    background-image: url('./assets/social/linkedin.png');
}

.socialIcon.github {
    background-image: url('./assets/social/github.png');
}

.socialIcon.email {
    background-image: url('./assets/social/email.png');
}

.resumeLink {
    display: inline-block;
    margin-top: 20px;
    font-size: 1em;
    font-weight: 600;
    text-decoration: none;
    color: #333;
    border: solid 1px #333;
    padding: 10px 20px 10px 20px;
    border-radius: 5px;
}

.resumeLink:hover {
    background-color: #333;
    color: #ffffff;
}

.footer {
    height: 80px;
    width: 100%;
    display: flex;
    flex-direction: row; /* Or column, if you want vertical stacking */
    justify-content: center; /* Centers horizontally in the container */
    align-items: center; /* Centers vertically in the container */
}

.footer .socialIcon {
    margin: 0px 10px 0px 10px;
}


.section {
    padding: 50px 0 50px 0;
}

.section:nth-child(odd) {
    background-color: rgb(250, 250, 250);
}

.section:nth-child(even) {
    background-color: #ffffff;
}

.sectionTitle {
    font-size: 3em;
    font-weight: 300;
    margin: 0;
    text-align: center;
    margin-bottom: 50px;
}

.sectionItemList {
    display: flex;
    flex-direction: column; /* Or column, if you want vertical stacking */
    justify-content: center; /* Centers horizontally in the container */
    align-items: center; /* Centers vertically in the container */
    margin: 0 20px 0 20px;
}

.sectionItemList a {
    text-decoration: none;
}

/* Mobile Layout */
@media screen and (max-width: 600px) {
    .hero {
        text-align: center;
        padding-top: 50px;
    }
    .headline {
        display: block;
    }

    .sectionItemList a {
        width: 100%;
    }

    .sectionItemList {
        margin: 0px;
    }
}





/******************************************************
 * 
 *  Desktop Menu Section
 * 
 ******************************************************/

.desktopMenu {
    display: flex;
    position: fixed;
    flex-direction: row; /* Or column, if you want vertical stacking */
    justify-content: center; /* Centers horizontally in the container */
    align-items: center; /* Centers vertically in the container */
    height: 40px; /* You might need to set a specific height if it's not filling its container */
    width: 100%;
    background-color: rgb(250, 250, 250);
    z-index: 1000;
    border-bottom: solid 1px #eeeeee;
    font-weight: 400;
}

.desktopMenu a {
    text-decoration: none;
    color: #333;
    font-size: 1em;
    margin: 0 15px 0 15px;
    text-align: center;
}

.desktopMenu button {
    text-decoration: none;
    color: #333;
    font-size: 1em;
    margin: 0 15px 0 15px;
    text-align: center;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.desktopMenu button:hover {
    text-decoration: underline;
}

.desktopMenu a:hover {
    text-decoration: underline;
}


/* Mobile Layout */
@media screen and (max-width: 600px) {
    .desktop {
        display: none;
    }
}

/******************************************************
 * 
 *  Mobile Menu Section
 * 
 ******************************************************/

.mobile {
    display: none;
}


/* Mobile Layout */
@media screen and (max-width: 600px) {
    /* Mobile Menu */
    .mobileMenu {
        display: none;
        box-sizing: border-box;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: #fff;
        z-index: 1000;
        text-align: center;
        padding-top: 50px;
    }

    .mobileMenu button {
        display: block;
        color: #333;
        font-size: 1.5em;
        font-weight: 400;
        text-decoration: none;
        margin: 0 auto 20px auto;
        background-color: transparent;
        border: none;
    }

    .mobileMenu a {
        display: block;
        color: #333;
        font-size: 1.5em;
        font-weight: 400;
        text-decoration: none;
        margin: 0 auto 20px auto;
    }

    .mobileMenu .closeButton {
        background: transparent;
        border: none;
        font-size: 24px; /* Adjust the size as needed */
        color: #333; /* Adjust the color as needed */
        cursor: pointer;
        position: absolute;
        top: 10px; /* Adjust the position as needed */
        right: 5px; /* Adjust the position as needed */
    }

    .mobileMenu .closeButton:hover {
        color: #666;
    }

    .showMenu {
        display: block;
    }

    .hamburgerIcon {
        display: block;
        position: fixed;
        top: 10px;
        right: 10px;
        cursor: pointer;
        width: 30px;
        height: 30px;
        background-color: #ffffff;
        border-radius: 5px;
        z-index: 1000;
        padding: 5px 5px 0px 5px;
        border: solid 1px #333;
    }

    .hamburgerIcon span {
        content: '';
        display: block;
        height: 2px;
        background-color: #333;
        margin: 5px 0;
    }

    .hamburgerIcon.showMenu {
        display: none;
    }
}



/******************************************************
 * 
 *  Skills Section
 * 
 ******************************************************/

.skillsGrid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, 150px);
    grid-template-areas:
        "ai pytorch computerVision nlp"
        "python react nodejs software"
        "other aws scrum crossfit";
    width: 100%;
}
  
.skillsItem {
    height: 100%;
    display: flex;
    justify-content: center; /* Centers horizontally in the container */
    align-items: center; /* Centers vertically in the container */
    transition: transform 0.3s ease; /* Smooth transition for the zoom effect */
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
}

.skillsItem .label {
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.8);
    color: #ffffff;
    padding: 10px 20px 10px 20px;
    border-radius: 5px;
    text-align: center;
}

.skillsItem.title {
    box-sizing: border-box;
    font-size: 1.5em;
    font-weight: 300;
    margin: 0;
    text-align: center;
    background-color: #eeeeee;
    padding: 10px;
}

.skillsItem.ai { grid-area: ai; }
.skillsItem.pytorch { grid-area: pytorch; }
.skillsItem.computerVision { grid-area: computerVision; }
.skillsItem.nlp { grid-area: nlp; }
.skillsItem.python { grid-area: python; }
.skillsItem.react { grid-area: react; }
.skillsItem.nodejs { grid-area: nodejs; }
.skillsItem.software { grid-area: software; }
.skillsItem.other { grid-area: other; }
.skillsItem.crossfit { grid-area: crossfit; }
.skillsItem.scrum { grid-area: scrum; }
.skillsItem.empty { grid-area: empty; }

.skillsItem.pytorch {
    background-image: url('./assets/skills/pytorch.png');
    transform: scale(0.8);
}

.skillsItem.computerVision {
    background-image: url('./assets/skills/imagenet.png');
    background-size: cover;
}

.skillsItem.nlp {
    background-image: url('./assets/skills/transformer.png');
    background-size: cover;
}

.skillsItem.python {
    background-image: url('./assets/skills/python.png');
    transform: scale(0.8);
}

.skillsItem.react {
    background-image: url('./assets/skills/react.png');
    transform: scale(0.6);
}

.skillsItem.nodejs {
    background-image: url('./assets/skills/nodejs.png');
    transform: scale(0.8);
}

.skillsItem.crossfit {
    background-image: url('./assets/skills/crossfit.png');
    transform: scale(0.8);
}

.skillsItem.scrum {
    background-image: url('./assets/skills/scrum.png');
    transform: scale(0.8);
}

.skillsItem.aws {
    background-image: url('./assets/skills/aws.png');
    transform: scale(0.8);
}

.skillsItem:hover {
    transform: scale(1.1); /* Scales up the item to 110% of its size */
}

/* Mobile Layout */
@media screen and (max-width: 600px) {
    .skillsGrid {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(6, 150px);
        grid-template-areas:
            "ai pytorch"
            "computerVision nlp"
            "software python"
            "react nodejs"
            "other aws"
            "scrum crossfit";
    }

    /* .skillsGrid {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(12, 150px);
        grid-template-areas:
            "ai"
            "pytorch"
            "computerVision"
            "nlp"
            "software"
            "python"
            "react"
            "nodejs"
            "other"
            "crossfit"
            "scrum"
            "empty";
    } */

    .skillsItem:hover {
        transform: none;
    }
}

/******************************************************
 * 
 *  Projects Section
 * 
 ******************************************************/

 .workItem {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 80px;
    border-style: solid;
    border-width: 1px;
    border-radius: 50px;
    background-color: #ffffff;
}

.workItem .logo {
    width: 50px;
    height: 50px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 20px;
}

.workItem .title {
    font-size: 1em;
    font-weight: 500;
    margin: 0;
}

.workItem .details {
    width: 210px;
}

.workItem .description {
    font-size: 1em;
    font-weight: 300;
    margin: 0;
}

.workItem.presence .logo {
    background-image: url('./assets/work/presence.png');
}






/******************************************************
 * 
 *  Work Section
 * 
 ******************************************************/

.workItem {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 80px;
    border-style: solid;
    border-width: 1px;
    border-radius: 50px;
    background-color: #ffffff;
}

.workItem .logo {
    width: 50px;
    height: 50px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 20px;
}

.workItem .title {
    font-size: 1em;
    font-weight: 500;
    margin: 0;
}

.workItem .details {
    width: 210px;
}

.workItem .description {
    font-size: 1em;
    font-weight: 300;
    margin: 0;
}

.workItem.presence .logo {
    background-image: url('./assets/work/presence.png');
}

.workItem.testai .logo {
    background-image: url('./assets/work/testai.jpeg');
}

.workItem.booknook .logo {
    background-image: url('./assets/work/booknook.png');
}

.workItem.emberlight .logo {
    background-image: url('./assets/work/emberlight.jpg');
}

.workItem.cisimple .logo {
    background-image: url('./assets/work/cisimple.png');
}

.workItem.breezy .logo {
    background-image: url('./assets/work/breezy.jpeg');
}

.workItem.vertigo .logo {
    background-image: url('./assets/work/vertigo.jpeg');
}

.workItem.disney .logo {
    background-image: url('./assets/work/disney.jpeg');
}

.workItem.uttexas .logo {
    background-image: url('./assets/education/ut-texas.png');
}

.workItem.ucf .logo {
    background-image: url('./assets/education/ucf.png');
}

.workItem.scrum .logo {
    background-image: url('./assets/education/scrum.png');
}

.workItem.usf .logo {
    background-image: url('./assets/education/usf.png');
}

.workItem.edx .logo {
    background-image: url('./assets/education/edx.jpeg');
}

.workItem.crossfit .logo {
    background-image: url('./assets/education/crossfit.jpeg');
}

/* Mobile Layout */
@media screen and (max-width: 600px) {
    .workItem {
        width: 300px;
        border-radius: 20px;
    }
}





/******************************************************
 * 
 *  Media Section
 * 
 ******************************************************/

.mediaItem {
    width: 500px;
    height: 280px;
    border-radius: 10px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 50px;
    /* filter: drop-shadow(5px 5px 10px #000000); */
    transition: transform 0.3s ease; /* Smooth transition for the zoom effect */
}

.mediaItem:hover {
    transform: scale(1.1); /* Scales up the item to 110% of its size */
}

.mediaItem.document {
    width: 385px;
    height: 500px;
}

.mediaItem.video .cover {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.5);
    color: #ffffff;
    font-size: 2em;
    font-weight: 300;
    margin: 0;
    text-align: center;
    background-image: url('./assets/media/play-icon.png');
    background-size: 100px;
    background-repeat: no-repeat;
    background-position: center;
    padding-top: 20px;
}

.mediaItem.video.aiinterview {
    background-image: url('./assets/media/ai-interview-video.jpg');
}

.mediaItem.slideDeck.autoGen {
    background-image: url('./assets/media/auto-gen-slides.jpg');
}

.mediaItem.slideDeck.agents {
    background-image: url('./assets/media/intro-to-agents.jpg');
}

.mediaItem.slideDeck.llms {
    background-image: url('./assets/media/intro-to-llms.jpg');
}

.mediaItem.slideDeck.sxsw {
    background-image: url('./assets/media/sxsw.jpeg');
}

.mediaItem.slideDeck.arduino {
    background-image: url('./assets/media/arduino.jpg');
}

.mediaItem.video.llmsvideo {
    background-image: url('./assets/media/intro-to-llms-video.jpg');
}

.mediaItem.document.cdi {
    background-image: url('./assets/media/cdi-cover.jpg');
}

/* Mobile Layout */
@media screen and (max-width: 600px) {
    .sectionItemList a {
        width: 100%;
    }

    .mediaItem {
        width: 100%;
        height: calc(100vw * 0.5625);
        border-radius: 0px;
        background-size: contain;
        background-position: center;
        transform: none;
    }

    .mediaItem:hover {
        transform: none;
    }
    .mediaItem.document {
        width: 300px;
        height: 400px;
        margin-left: auto;
        margin-right: auto;
    }

    .mediaItem.video .cover {
        font-size: 1em;
        background-size: 50px;
        border-radius: 0px;
    }
}