.root {
    box-sizing: border-box;
    flex-direction: row;
    justify-content: center;
    width: 600px;
    padding: 10px 20px 30px 30px;
    margin-bottom: 50px;
    color: #000000;
    background-color: #ffffff;
    transition: transform 0.3s ease;
    border-bottom: 1px solid #e1e4e8;
}

.header {
    width: 100%;
    display: block;
    margin-bottom: 10px;
}

.profile {
    width: 50px;
    height: 50px;
    background-size: 50px;
    border-radius: 25px;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center center;
    vertical-align: top;
}

.details {
    margin-top: 5px;
    display: inline-block;
    font-size: 1em;
    vertical-align: top;
    padding-left: 10px;
    vertical-align: top;
}

.title {
    font-weight: 500;
    margin: 0;
}

.recommendation {
    font-style: italic;
}

/* Mobile Layout */
@media screen and (max-width: 600px) {
    .root {
        display: block;
        width: 100%;
    }
    .header {
        text-align: center;
        width: 100%;
    }

    .profile {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .details {
        text-align: center;
        padding: 0;
    }

    .recommendation {
        padding: 0px 20px 0px 20px;
        text-align: center;
    }
}