.root {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 400px;
    padding: 10px 20px 30px 30px;
    margin-bottom: 50px;
    color: #000000;
    background-color: #ffffff;
    transition: transform 0.3s ease;
    border-bottom: 1px solid #e1e4e8;
}

.root:hover {
    transform: scale(1.05);
}

.logo {
    width: 150px;
    height: 100px;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 80px;
}

.details {
    display: inline-block;
    width: 100%;
    padding-left: 20px;
}

.title {
    font-size: 1em;
    font-weight: 500;
    margin: 0;
}

.stats {
    display: inline-block;
    background-size: 12px 12px;
    background-position: 0px 4px;
    background-repeat: no-repeat;
    padding-left: 15px;
}

.stats.stars {
    background-image: url('./assets/star_icon.png'); 
}

.stats.forks {
    margin-left: 15px;
    background-image: url('./assets/fork_icon.png'); 
}

.description {
    font-size: .75em;
    font-weight: 300;
    margin: 0;
    margin-top: 5px;
}

/* Mobile Layout */
@media screen and (max-width: 600px) {
    .root {
        display: block;
        width: 100%;
    }

    .root:hover {
        transform: none;
    }

    .logo {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .details {
        text-align: center;
        padding: 0;
    }

    .description {
        padding: 0px 20px 0px 20px;
    }
}