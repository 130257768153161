.root {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 600px;
    padding: 10px 20px 30px 30px;
    margin-bottom: 50px;
    color: #000000;
    background-color: #ffffff;
    transition: transform 0.3s ease;
    border-bottom: 1px solid #e1e4e8;
}

.image {
    width: 100%;
    height: 100%;
    margin: 10px auto 0px auto;
}

.details {
    display: inline-block;
    width: 100%;
    padding-left: 20px;
}

.links {
    margin: 5px 0px 5px 0px; 
}

.details .link{
    text-decoration: none;
    font-weight: 600;
    color: rgb(87, 197, 224);
    margin-right: 15px;
}

.details a:hover {
    text-decoration: underline;
}

.name {
    font-size: 1em;
    font-weight: 600;
    margin: 0;
}

.description {
    /* font-size: .75em; */
    font-size: 1em;
    font-weight: 300;
    margin: 0;
    margin-top: 5px;
}

/* Mobile Layout */
@media screen and (max-width: 600px) {
    .root {
        display: block;
        width: 100%;
    }

    .root:hover {
        transform: none;
    }

    .logo {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .details {
        text-align: center;
        padding: 0;
    }

    .description {
        padding: 0px 20px 0px 20px;
    }
}